<template>
  <section class="order-details text-center">
    <h5 class="page-title">Детали заказа #{{ details.id || '' }}</h5>
    <div class="mt-4">
      <div class="order-details__info">
        Список заказа:
        <ul>
          <li v-for="item in details.items" :key="item.id">- {{ item.name }} ({{ item.price }} ₸)</li>
        </ul>
      </div>

      <div class="order-details__info">
        Дата заказа:
        <span class="font-weight-bolder">{{ new Date(details.created_at).toLocaleDateString('ru-RU') }}</span>
      </div>
      <div class="order-details__info">
        Текущий статус:
        <span class="font-weight-bolder text-info">{{ getOrderStatus(details.status) }}</span>
      </div>
      <div class="order-details__info">
        Цена доставки:
        <span class="font-weight-bolder">{{ details.delivery_amount ? details.delivery_amount : 0 }} ₸</span>
      </div>
      <div class="order-details__info">
        Сумма заказа:
        <span class="font-weight-bolder">{{ details.sum ? details.sum : 0 }} ₸</span>
      </div>
      <div v-if="details.file" class="order-details__info mt-3">
        <b-button @click="redirectToFile" variant="outline-secondary" size="sm"> Скачать прикрепленный файл </b-button>
      </div>
      <div v-if="details.text" class="order-details__info mt-3">
        Дополнительная информация:
        <p class="mt-1">{{ details.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'OrderDetailsPage',
  data: () => ({
    details: {},
  }),
  async mounted() {
    try {
      const orderNumber = this.$route.params.id
      const { order } = await this.wrapAndHandleRequest(() => Api.gerOrderInfo(orderNumber))
      this.details = order
      console.log(order)
    } catch (e) {
      await this.$router.push({ name: 'layout.main' })
      this.makeToast('Детали заказа', 'Ошибка при просмотре деталей заказа', 'danger')
    }
  },

  methods: {
    redirectToFile() {
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', this.details.file)
      link.click()
    },

    getOrderStatus(status) {
      switch (status) {
        case 1:
          return 'Создан'
        case 2:
          return 'Оплачен'
        case 3:
          return 'В ожидании оплаты'
        case 4:
          return 'Отправлен'
        case 5:
          return 'Отгружен'
        case 6:
          return 'Доставлен'
        case 7:
          return 'Отменен'
        default:
          return '-'
      }
    },
  },
}
</script>
